import { firebaseStorage } from "../lib/firebase";
import { ref, deleteObject } from "firebase/storage";


const deleteFromFireBase = async (url) => {
    console.log("test")
    const storageRef = ref(firebaseStorage, url);
    try {
        await deleteObject(storageRef);
        return true;
    }
    catch (err) {
        console.log(err)
        return false;
    }
}

export default deleteFromFireBase;