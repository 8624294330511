import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { firebaseApp, googleProvider } from "../../lib/firebase";
import Input from '../../components/atoms/input';
import PrimaryButton from '../../components/atoms/primaryButton';
import Typeaheads from '../../components/atoms/typeaheads';
import { debounce } from "lodash";
import Axios from '../../AxiosConfig';
import { useNavigate } from "react-router-dom";

const SignUp = () => {
    const auth = getAuth(firebaseApp);
    const navigate = useNavigate();
    const [formObject, setFormObject] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [error, setError] = useState('');
    const [show, setshow] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [final, setfinal] = useState('');
    const [counter, setCounter] = useState(0);
    const [otp, setotp] = useState('');
    const [timer, setTimer] = useState(0);
    const addOnText='+1';
    const startCounter = () => {
        setCounter(59);
        setTimer(setInterval(() => {
            setCounter((prev) => prev - 1);
        }, 1000));
    }
    useEffect(() => {
        if (counter === 0) {
            clearInterval(timer);
        }
    }, [counter]);

    const signin = () => {
        try {
            setError("");
            if (formObject.phone === "" || formObject.phone.length !== 10) {
                setError("Please enter valid phone number");
                setisLoading(false);
                return;
            }

            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {
                    console.log("recaptcha solved")
                },
                'expired-callback': () => {
                    console.log("recaptcha expired")
                }
            });
            setisLoading(true);
            signInWithPhoneNumber(auth,addOnText + formObject.phone, window.recaptchaVerifier).then((result) => {
                console.log("resultsssssss",result)
                setfinal(result);
                setshow(true);
                setisLoading(false);
                startCounter();
                // window.dataLayer.push({
                //     event:'signup_clicked',
                //     value:'signup_clicked',
                // })
                
            })
                .catch((err) => {
                    console.log("signIn", err.message ,typeof err);
                    setisLoading(false);
                    if('message' in err){
                    if(err.message === 'auth/invalid-phone-number'){
                        setError("Please enter valid phone number");
                    }
                }
                    else{
                    setError("Please enter valid phone number");
                    }
                    // may required in other catch 
                    setTimeout(() => { window.recaptchaVerifier.clear() 
                      let div = document.getElementById('recaptcha-container');
                      div.remove();
                        let div2 = document.createElement('div');
                        div2.id = 'recaptcha-container';
                        // append it to div 
                        document.getElementById('submit-div').appendChild(div2);
                    }, 2000);
                      });
        }
        catch (err) {
            setisLoading(false);
            setError("Something went wrong");
            setTimeout(() => { window.recaptchaVerifier.clear() 
                let div = document.getElementById('recaptcha-container');
                div.remove();
                  let div2 = document.createElement('div');
                  div2.id = 'recaptcha-container';
                  // append it to div 
                  document.getElementById('submit-div').appendChild(div2);
              }, 2000);
            

        }

    }

    


    // Validate OTP
    const ValidateOtp = async () => {
        setError("");
        if (otp === null || final === null) {
            setError("Please enter valid verification code");
            return;
        }
        setisLoading(true);
        final.confirm(otp).then(async (result) => {
            if (result) {
                setisLoading(false);
                console.log("result", result);
               
                // add a variable to a script tag
               formObject['phoneWithCode'] = addOnText + formObject.phone;
               const res= await Axios.post('/onboarding/update-leads', formObject)  
            //    console.log(res)
               if(res.data.success){
                // await addContacts(formObject);
                // console.log("success")
                // window.dataLayer.push({
                //     event:'otp_clicked',
                //     value:'otp_clicked'
                // })
                navigate('/profile')
               }
               else{
                console.log("what?")
                window.location.reload();
               }
                // navigate('/profile') 
            }
            // success
        }).catch((err) => {
            setError("Wrong code");
            setisLoading(false);
        })
    }

    const resendOTP = () => {
        setisLoading(true);
        startCounter();
        setError("");
        signInWithPhoneNumber(auth, addOnText + formObject.phone, window.recaptchaVerifier)
            .then((confirmationResult) => {
                setshow(true);
                setisLoading(false);
                window.confirmationResult = confirmationResult;
                console.log("OTP has been resent");
            }).catch((error) => {
                setCounter(0);
                setshow(false);
                setisLoading(false);
                setError(" Something went wrong");
                console.log("SMS not sent");
            });

    }

    const handleFieldChange = (field, value) => {
        setFormErrors({});
        setError("");
        let tempFormObject = { ...formObject };
        let fieldArray = field.split(".");
        // console.log(fieldArray)
        if (fieldArray.length === 1) {
            tempFormObject[field] = value;
        } else if (fieldArray.length === 2) {
            // check if field is undefined
            if (!tempFormObject[fieldArray[0]]) {
                tempFormObject[fieldArray[0]] = {};
            }
            tempFormObject[fieldArray[0]][fieldArray[1]] = value;
        }
        // console.log(tempFormObject)
        setFormObject(tempFormObject);
    }
    const validateFields = () => {
        let errors = {};
        if (!formObject?.name) {
            errors.name = '*Name field is required'
        }
        if (formObject?.name) {
            if (!(new RegExp(/^(?!.*\d)[A-Za-z\s]*[A-Za-z]{2,}(?:[A-Za-z\s]+){0,8}$/).test(formObject?.name))) {
                errors.name = '*Name should contain only alphabets'
            }
            if (formObject?.name.length < 2 || formObject?.name.length > 30) {
                errors.name = '*Name should contain 2 to 30 characters'
            }
        }
        if (!formObject.email) {
            errors.email = '*Email field is required';
        }
        if (formObject?.email) {
            if (!(new RegExp(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)*(\.[a-zA-Z]{2,})$/).test(formObject?.email))) {
                errors.email = '*Please enter valid email';
            }
        }
        if (!formObject.phone) {
            errors.phone = '*Phone field is required';
        }
        if (!formObject.addressObj || Object.keys(formObject.addressObj).length === 0) {
            errors.addressObj = '*City field is required';
        }
        if (!formObject.categoryObj || Object.keys(formObject.categoryObj).length === 0) {
            errors["categoryObj"] = "*Category field is required";
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = validateFields();
        let tempFormObject = { ...formObject };
        tempFormObject['phoneWithCode'] = addOnText + formObject.phone;
        setisLoading(true);
        if (isValid) {
            if ('addressObj' in tempFormObject) {
                tempFormObject['address'] = tempFormObject.addressObj.label;
            }
            if ('categoryObj' in tempFormObject) {
                tempFormObject['category'] = tempFormObject.categoryObj.label;
            }
            setFormObject(tempFormObject);
            setFormErrors({});
            Axios.post('/onboarding/create-leads', tempFormObject).then((res) => {
                if (res.data.success) {
                    signin();
                }
                if (res.data.error) {
                    setisLoading(false);
                    setError('Something Went Wrong Try Again')
                }
                if (res.data.message) {
                    setisLoading(false);
                    setError(res.data.message)
                }
            }).catch((err) => {
                console.log(err)
                setisLoading(false);
                setError('Something Went Wrong Try Again')
            })
        }
        else{
            setisLoading(false);
        }

    }

    useEffect(() => {
        if (window.recaptchaVerifier) {
            if ('current' in window.recaptchaVerifier) {
                window.recaptchaVerifier.current.reset()
            }
           
        }
       
        
    }
        , [])

    return (

        <div className="w-full bg-cover bg-[url('https://images.unsplash.com/photo-1559456751-057ed03f3143?q=80&w=3001&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')] 
        p-4 md:p-4 items-center h-[940px] lg:h-screen md:min-h-screen ">
        
            <div className='flex flex-row lg:flex-col items-start md:items-start lg:items-center justify-center h-full'>     
                <div className='w-full sm:w-full lg:w-1/2 bg-white p-8 rounded-lg shadow-lg '>
                    <div className='flex justify-start py-2'>
                        <div className='w-full'>
                            <h1 className='text-2xl font-semibold '>Ready to Start Your Journey!</h1>
                            <p className=' text-md font-normal  text-gray-500'>Welcome to the game-changing platform designed exclusively for event services vendors</p>
                           
                        </div>
                    </div>
                    <div className='mt-2' style={{ display: !show ? "block" : "none" }}>
                        <p className='text-red-700 text-center text-sm'>{error}</p>
                        <div>
                            <Input
                                label="Buisness Name"
                                name="name"
                                placeholder="Enter your buisness name"
                                value={formObject.name}
                                isOptional={true}
                                optionalText={'*Required'}
                                onChange={
                                    (value) => handleFieldChange('name', value)
                                }
                                msgText={"If you don't have a buisness name, enter your full name"}
                            />
                            <span className="text-red-700 text-sm font-semibold"> {formErrors.name}</span>
                        </div>
                        <div className='flex flex-col sm:flex-col lg:flex-row md:flex-row gap-8 mt-5'>
                            <div className="w-full">
                                <Input
                                    label="Work Email"
                                    placeholder="Enter your Email"
                                    name="email"
                                    value={formObject.email}
                                    isOptional={true}
                                    optionalText={'*Required'}
                                    onChange={(value) =>
                                        handleFieldChange('email', value)
                                    }
                                    msgText={'This email will be used for all communication'}
                                />
                                <span className="text-red-700 text-sm font-semibold"> {formErrors.email}</span>
                            </div>
                            <div className="w-full">
                                <Input
                                    label="Mobile Number"
                                    placeholder="Enter your Mobile Number"
                                    value={formObject.phone}
                                    addOn={true}
                                    type='number'
                                    addOnText={addOnText}
                                    isOptional={true}
                                    optionalText={'*Required'}
                                    onChange={(value) =>
                                        handleFieldChange('phone',  value)
                                    }
                                    msgText={'A verification code will be sent to this number'}
                                />
                                <span className="text-red-700 text-sm font-semibold"> {formErrors.phone}</span>
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-col md:flex-row  lg:flex-row gap-4 mt-6 '>
                            <div className="w-full">
                                <div className='flex justify-between'>
                                <p className="text-sm font-semibold leading-6 text-gray-900 mb-2">Current City</p>
                                <span className='text-gray-700 text-xs font-semibold relative top-1'>*Required</span>
                                </div>
                                <Typeaheads
                                    onChange={(value) => {
                                        console.log(value)
                                        debounce(() => {
                                            handleFieldChange('addressObj', value)}
                                            , 300)()
                                    }}
                                    apiURL={'/locations/get-locations'}
                                    defaultValue={formObject?.addressObj}
                                    placeholder="Denver, Aurora, Boulder, etc."
                                />
                                <span className="text-red-700 text-sm font-semibold"> {formErrors.addressObj}</span>
                            </div>
                            <div className="w-full">
                            <div className='flex justify-between'>
                                <p className="text-sm font-semibold leading-6 text-gray-900 mb-2">Buisness Category</p>
                                <span className='text-gray-700 text-xs font-semibold relative top-1'>*Required</span>
                                </div>

                                <Typeaheads
                                    onChange={(value) =>
                                        debounce(() => {
                                            handleFieldChange('categoryObj', value)
                                        }, 300)()
                                    }
                                    apiURL={'/categories/get-categories'}
                                    defaultValue={formObject.categoryObj}
                                    placeholder="Singer, DJ, Photographer, etc."
                                />
                                <span className="text-red-700 text-sm font-semibold"> {formErrors.categoryObj}</span>
                            </div>
                        </div>
                        <div className='flex justify-center mt-4' id="submit-div">
                        <div id="recaptcha-container"></div>
                        <div>
                        <PrimaryButton
                            type="button"
                            onClick={handleSubmit}
                            text={!isLoading ? "Sign Up" : "Sending Code ...."}
                            classNames="mx-auto"
                        />
                        </div>
                        <div>
                       
                        </div>
                    </div>
                    <p className='text-sm text-center mt-2'> 
                            Already have an account? <a href="/login" className='text-blue-700'>Login</a>
                        </p>
                    </div>
                    
                    <div style={{ display: show ? "block" : "none" }}>
                    <div className=' w-full'>
                        <p className="text-red-700 text-center">{error}</p>
                        <Input
                            label='Verification Code'
                            placeholder='Enter Verification Code'
                            value={otp}
                            onChange={(value) =>
                                setotp(value)
                            }
                            type='number'
                            msgText={`Sent to ${addOnText + formObject.phone}`}
                            isOptional={true}
                            optionalText='*Required'
                        />
                        <p className="text-gray-600 cursor-pointer text-sm">
                            {counter === 0 ?
                                <span onClick={resendOTP} className="text-blue-400">Resend Verification Code</span>
                                : <span className="text-gray-600">Resend Code in 00:{counter}</span>}
                        </p>

                    </div>
                    <div className='w-full flex justify-center mt-3 lg:mt-0'>
                        <PrimaryButton
                            onClick={ValidateOtp}
                            text={!isLoading ? "Verify Code" : "Verifying ...."}
                            className="mx-auto"
                        />
                        
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
export default SignUp;