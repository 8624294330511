import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { firebaseApp, googleProvider } from "../../lib/firebase";
import Input from '../../components/atoms/input';
import PrimaryButton from '../../components/atoms/primaryButton';
import Axios from '../../AxiosConfig';
import { useNavigate } from "react-router-dom";


const Login = () => {
    const auth = getAuth(firebaseApp);
    const navigate = useNavigate();
    const [formObject, setFormObject] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [error, setError] = useState('');
    const [show, setshow] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [final, setfinal] = useState('');
    const [counter, setCounter] = useState(0);
    const [otp, setotp] = useState('');
    const [timer, setTimer] = useState(0);
    const addOnText='+1';
    const startCounter = () => {
        setCounter(59);
        setTimer(setInterval(() => {
            setCounter((prev) => prev - 1);
        }, 1000));
    }
    useEffect(() => {
        if (counter === 0) {
            clearInterval(timer);
        }
    }, [counter]);
     const validateUser = async () => {
        let user = await Axios.post('/onboarding/find-leads',{phone:formObject.phone})
        console.log(user)
        if(user.data?.message === 'User found'){
        return true
        }
        else{
            return false
        }
     }
    const signin = async () => {
        try {
            setError("");
            if (formObject.phone === "" || formObject.phone.length !== 10) {
                setError("Please enter valid phone number");
                setisLoading(false);
                return;
            }
            const user = await validateUser();
            console.log('after---',user)
            if(!user){
                setError("User not found!");
                setisLoading(false);
                return;
            }
            else if(user){
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {
                    console.log("recaptcha solved")
                },
                'expired-callback': () => {
                    console.log("recaptcha expired")
                }
            });
            setisLoading(true);
            signInWithPhoneNumber(auth,addOnText + formObject.phone, window.recaptchaVerifier).then((result) => {
                console.log("resultsssssss",result)
                setfinal(result);
                setshow(true);
                setisLoading(false);
                startCounter();
            })
                .catch((err) => {
                    console.log("signIn", err);
                    setisLoading(false);
                    if(err.message === 'auth/invalid-phone-number'){
                        setError("Please enter valid phone number");
                    }
                    else{
                    setError("Please enter valid phone number");
                    }
                    // may required in other catch 
                    setTimeout(() => { window.recaptchaVerifier.clear() 
                      let div = document.getElementById('recaptcha-container');
                      div.remove();
                        let div2 = document.createElement('div');
                        div2.id = 'recaptcha-container';
                        // append it to div 
                        document.getElementById('submit-div').appendChild(div2);
                    }, 2000);
                      });
        }
    }
        catch (err) {
            console.log("signIn", err);
            setisLoading(false);
            setError("Something went wrong");

        }

    }

    // Validate OTP
    const ValidateOtp = async () => {
        setError("");
        if (otp === null || final === null) {
            setError("Please enter valid verification code");
            return;
        }
        setisLoading(true);
        final.confirm(otp).then(async (result) => {
            if (result) {
                setisLoading(false);
                const res= await Axios.post('/onboarding/update-leads', formObject)  
               console.log(res)
               if(res.data.success){
                console.log("success")
                navigate('/profile')
               }
               else{
                console.log("what?")
                window.location.reload();
               }
            }
            // success
        }).catch((err) => {
            setError("Wrong `code");
            setisLoading(false);
        })
    }

    const resendOTP = () => {
        setisLoading(true);
        startCounter();
        setError("");
        signInWithPhoneNumber(auth, addOnText + formObject.phone, window.recaptchaVerifier)
            .then((confirmationResult) => {
                setshow(true);
                setisLoading(false);
                window.confirmationResult = confirmationResult;
                console.log("OTP has been resent");
            }).catch((error) => {
                setCounter(0);
                setshow(false);
                setisLoading(false);
                setError(" Something went wrong");
                console.log("SMS not sent");
            });

    }

    const handleFieldChange = (field, value) => {
        setFormErrors({});
        setError("");
        let tempFormObject = { ...formObject };
        let fieldArray = field.split(".");
        // console.log(fieldArray)
        if (fieldArray.length === 1) {
            tempFormObject[field] = value;
        } else if (fieldArray.length === 2) {
            // check if field is undefined
            if (!tempFormObject[fieldArray[0]]) {
                tempFormObject[fieldArray[0]] = {};
            }
            tempFormObject[fieldArray[0]][fieldArray[1]] = value;
        }
        // console.log(tempFormObject)
        setFormObject(tempFormObject);
    }
    const validateFields = () => {
        let errors = {};
       
        if (!formObject.phone) {
            errors.phone = '*Phone field is required';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = validateFields();
        let tempFormObject = { ...formObject };
        setisLoading(true);
        if (isValid) {
            setFormObject(tempFormObject);
            setFormErrors({});
            signin();
        }
        else{
            setisLoading(false);
        }

    }

    useEffect(() => {
        if (window.recaptchaVerifier) {
            if ('current' in window.recaptchaVerifier) {
                window.recaptchaVerifier.current.reset()
            }
        }
    }
        , [])

    return (

        <div className="w-full bg-cover bg-[url('https://images.unsplash.com/photo-1559456751-057ed03f3143?q=80&w=3001&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')] p-4 lg:p-1 items-center h-screen">
            <div className='flex flex-col sm:flex-row lg:flex-col items-center justify-center h-full '>     
                <div className='w-full sm:w-full lg:w-1/2 bg-white p-8 rounded-lg shadow-lg border-t-8 border-t-gray-900 '>
                    <div className='flex justify-start py-2'>
                        <div className='w-full'>
                            <h1 className='text-2xl font-semibold '>Ready to Start Your Journey!</h1>
                            <p className=' text-md  text-gray-500'>Welcome to the game-changing platform designed exclusively for event services vendors</p>
                            
                        </div>
                    </div>
                    <div className='mt-2' style={{ display: !show ? "block" : "none" }}>
                    <p className="text-red-700 text-center text-sm">{error}</p>
                        <div className='flex flex-row gap-8 mt-6'>
                            <div className="w-full">
                                <Input
                                    label="Phone Number"
                                    placeholder="Enter your PhoneNumber"
                                    value={formObject.phone}
                                    addOn={true}
                                    addOnText={addOnText}
                                    type = "number"
                                    isOptional={true}
                                    optionalText={'*Required'}
                                    onChange={(value) =>
                                        handleFieldChange('phone',  value)
                                    }
                                />
                                <span className="text-red-700 text-sm font-semibold"> {formErrors.phone}</span>
                            </div>
                        </div>
                        
                        <div className='flex justify-center mt-4' id="submit-div">
                        <div id="recaptcha-container"></div>
                        <div>
                        <PrimaryButton
                            type="button"
                            onClick={handleSubmit}
                            text={!isLoading ? "Log In" : "Sending Code ...."}
                            classNames="mx-auto"
                        />
                        </div>
                        <div>
                       
                        </div>
                    </div>
                    <p className='text-sm text-center mt-2'> 
                            Not a vendor? <a href="/" className="text-blue-700">Sign Up</a>
                        </p>
                    </div>
                    
                    <div style={{ display: show ? "block" : "none" }}>
                    <div className=' w-full'>
                        <p className="text-red-700 text-center text-sm">{error}</p>
                        <Input
                            label='Verification Code'
                            placeholder='Enter your verification code'
                            value={otp}
                            onChange={(value) =>
                                setotp(value)
                            }
                            type='number'
                            msgText={`Sent to ${addOnText + formObject.phone}`}
                            isOptional={true}
                            optionalText='*Required'
                        />
                        <p className="text-gray-600 cursor-pointer text-sm">
                            {counter === 0 ?
                                <span onClick={resendOTP} className="text-blue-400">Resend Verification Code</span>
                                : <span className="text-gray-600">Resend Code in 00:{counter}</span>}
                        </p>

                    </div>
                    <div className='w-full flex justify-center mt-3 lg:mt-0'>
                        <PrimaryButton
                            onClick={ValidateOtp}
                            text={!isLoading ? "Verify Code" : "Verifying ...."}
                            className="mx-auto"
                        />
                        
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
export default Login;