import  React , {useState , useEffect}from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import PrimaryButton from '../../components/atoms/primaryButton';
import Axios from '../../AxiosConfig';
export default function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState({});
  useEffect( () => {
     Axios.post("/onboarding/find-vendor" , {}).then((res)=>{
      if(res.data){
        setUser(res.data)
      }
      else{
        setUser({})
      }
    }).catch((err)=>{
      console.log(err)
    })
    
  }, []);
  
  return (
    <div className="bg-white py-44 ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-blue-600">Welcome {" "} 👋 {" "}
            {user && user.name ? user.name : 'Guest' } 
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Let's Find You an Event
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Upload you details and we will find you an event that matches your profile.
          </p>
        </div>
        <div className="mx-auto mt-2 max-w-2xl sm:mt-10 lg:mt-12 lg:max-w-4xl text-center">
          <PrimaryButton text="Go to Profile"
           onClick={()=>{
              navigate('/profile')
           }}     
           classNames="items-center"      
           
           />
        </div>
      </div>
    </div>
  )
}
