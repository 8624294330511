import { CheckIcon } from '@heroicons/react/24/solid';
import React from 'react'
import { useState } from 'react';

export default function Tabs({ tabArray,changeTab }) {
     const [tabs, setTabs] = useState(tabArray);
     console.log(tabs)
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
    return (
        <>
             <div>
      <div className="">
        <nav className="isolate flex flex-col lg:flex-row divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
              )}
              onClick = {() => changeTab(tab.name)}
              aria-current={tab.current ? 'page' : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? 'bg-blue-700' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </a>
          ))}
        </nav>
      </div>
    </div>
        </>
    )
}
