import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import Steps from '../../components/molecules/steps'
import Tabs from '../../components/molecules/tabs';
import Personalnformation from './personalnformation'
import Media from './media';
import BuisnessInformation from './buisnessInformation'
import toast from 'react-simple-toasts';
import Axios from '../../AxiosConfig'
import Modals from '../../components/molecules/modals'
import { SparklesIcon,CurrencyDollarIcon , InformationCircleIcon , PhotoIcon } from '@heroicons/react/24/solid'
import PrimaryButton from '../../components/atoms/primaryButton';
import PartyLoader from '../../components/loader/partyLoader'

export default function Profile() {
  const [data, setData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    getVendorData();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';

      // Display confirmation message
      const confirmationMessage = 'Are you sure you want to leave this page?';
      e.returnValue = confirmationMessage; // For legacy browsers

      return confirmationMessage;
    };
    // console.log("main", data)
    if (!data?.is_profile_completed) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    console.log("main")
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    
  }, [data]);

  // get vendor data from backend
  const getVendorData = () => {
    Axios.post('/onboarding/find-vendor', {}).then((res) => {
      if (res.data) {
        let tempData = res.data
        if (!res.data.is_profile_completed && !res.data?.about) {
          openModal()
        }
        // creating obj for typeaheads component
        if (tempData?.address) {
          let val = tempData?.address.replace(',', '').replace(/[^a-zA-Z0-9 ]/g, '-').replace(/\s+/g, '-').toLowerCase();
          tempData.addressObj = { label: tempData.address, value: val }
        }
        if (tempData?.locations) {
          tempData.locationsArr = tempData.locations.map((location) => {
            let val = location.replace(',', '').replace(/[^a-zA-Z0-9 ]/g, '-').replace(/\s+/g, '-').toLowerCase();
            return { label: location, value: val }
          })
        }
        if (tempData?.category) {
          let val = tempData?.category.replace(',', '').replace(/[^a-zA-Z0-9 ]/g, '-').replace(/\s+/g, '-').toLowerCase();
          tempData.categoryObj = { label: tempData.category, value: val }
        }
        if (tempData?.services) {
          tempData.servicesArr = tempData.services.map((service) => {
            let val = service.replace(',', '').replace(/[^a-zA-Z0-9 ]/g, '-').replace(/\s+/g, '-').toLowerCase();
            return { label: service, value: val }
          })
        }
        if('charges' in tempData){
          if(tempData.charges === null || tempData.charges === undefined){
            tempData.charges.per='Hour'
          }
        }
        else{
          tempData.charges = {per:'Hour'}
        }
        if('media' in tempData){
          if(tempData.media.length === 0 || tempData.media === null || tempData.media === undefined){
            tempData.media = []
          }
        }
        else{
          tempData.media = []
        }
        if('slug' in tempData){
          if(tempData.slug === null || tempData.slug === undefined){
            tempData.slug = ''
          }
        }
        else{
          tempData.slug = ''
        }
        console.log("tempData",tempData);
        setData(tempData)

      }
      else {
        toast(res.data.message, { duration: 3000, clickable: true, className: 'my-toast-failure' });
      }
    }).catch((err) => {
      console.log(err)
      toast('Something went wrong', { duration: 3000, clickable: true, className: 'my-toast-failure' });
    })
  }

  // data to be passed to child component
  const personalnformation = {
    'name': data.name,
    'email': data.email,
    'phone': data.phone,
    'addressObj': data.addressObj,
    'about': data.about,
    'imageUrl': data?.imageUrl,
    'coverImageUrl': data?.coverImageUrl,
    'mainAddress': data?.mainAddress,
    'locationsArr': data.locationsArr,
    'zipCode': data?.zipCode,
    'user_id': data?.user_id,
    'slug': data?.slug,
  }
  const media = {
    'media': data?.media,
    'user_id': data?.user_id,

  }
  const buisnessInformation = {
    'charges': data?.charges,
    'categoryObj': data?.categoryObj,
    'servicesArr': data?.servicesArr,
    'travelRange': data?.travelRange,
    'canTravelNationwide': data?.canTravelNationwide ? data?.canTravelNationwide : false,
  }

  const [modal, setModal] = useState(false);

  const openModal = () => {
    setModal(true);
  }
  const [tabs, setTabs] = useState(
  [{ id: '01', name: 'Basic Information',current:true, href: '#', status: 'current', description: 'Tell us about your buisness',icon : <InformationCircleIcon className="h-6 w-6 " aria-hidden="true" /> },
  { id: '02', name: 'Media', href: '#', status: 'upcoming',current:false, description: 'Showcase your talent',icon : <PhotoIcon className="h-6 w-6 " aria-hidden="true" /> },
  { id: '03', name: 'Booking Information', href: '#',current:false, status: 'upcoming', description: "Let's setup your booking", icon : <CurrencyDollarIcon className='h-6 w-6 '/> },])
  const [tabName, setTabName] = useState(tabs[0].name);

  // get data from child component and maintain the state
  const getData = (dataObj, activeTab, action) => {
    let tempData = { ...data };
    let tempTabs = [...tabs];
    let currentTab = tempTabs.findIndex((tab) => (tab.name === activeTab));
    if (action === 'Next') {
      tempTabs[currentTab].status = 'complete';
      tempTabs[currentTab].current = false;
      tempTabs[currentTab + 1].status = 'current';
      tempTabs[currentTab + 1].current = true;
      setTabs(tempTabs);
      setTabName(tempTabs[currentTab + 1].name);
      tempData = { ...tempData, ...dataObj }
      setData(tempData);
      updateVendor(tempData);
    }
    if (action === 'Back') {
      tempTabs[currentTab].status = 'upcoming';
      tempTabs[currentTab].current = false;
      tempTabs[currentTab - 1].status = 'current';
      tempTabs[currentTab - 1].current = true;
      setTabs(tempTabs);
      setTabName(tempTabs[currentTab - 1].name);
      tempData = { ...tempData, ...dataObj }
      setData(tempData);
    }
    if (action === 'Submit') {
      tempTabs[2].status = 'complete';
      tempTabs[2].current = false;
      tempTabs[0].current = true;
      setTabs(tempTabs);
      setTabName('Basic Information');
      tempData = { ...tempData, ...dataObj }
      setData(tempData);
      updateVendor(tempData, 'Submit');

    }
    if(action === 'Save'){
      tempData = { ...tempData, ...dataObj }
      setData(tempData);
      updateVendor(tempData);
    }
  }

  // change tabs when profile is complete
  const changeTab = (tabName) => {
    console.log(tabName)
    let tempTabs = [...tabs];
    let currentTab = tempTabs.findIndex((tab) => (tab.name === tabName));
    tempTabs.map((tab) => {
      tab.current = false
    })
    tempTabs[currentTab].current = true;
    setTabs(tempTabs);
    setTabName(tabName);
  }


  // update vendor data
  const updateVendor = (vendorData, action = '') => {
    if (action === 'Submit') {
      vendorData['is_profile_completed'] = true;
      setData({})
    }
    Axios.post('/onboarding/update-vendor', vendorData).then((res) => {
      if (res.data.success) {
        toast('Profile Updated', { duration: 3000, clickable: true, className: 'my-toast-success', position: 'top-right' });
        getVendorData();
        if(vendorData?.is_profile_completed){
          let user = window.localStorage.getItem('user')
          user = JSON.parse(user)
          user.displayName = vendorData.name
          user.email = vendorData.email
          user.photoURL = vendorData.imageUrl
          window.localStorage.setItem("user", JSON.stringify(user));
          navigate('/profile')
        }
        

      }
      else {
        toast('Something Went Wrong', { duration: 3000, clickable: true, className: 'my-toast-failure', position: 'top-right' });
      }
    }).catch((err) => {
      console.log(err)
      toast('Something went wrong', { duration: 3000, clickable: true, className: 'my-toast-failure', position: 'top-right' });
    })
  }


  const [blur , setBlur] = useState(false)
  const tabContent = {
    'Basic Information':
      <Personalnformation
        data={personalnformation}
        getData={getData}
        is_profile_completed={data?.is_profile_completed}
      />,

    'Media':
      <Media
        data={media}
        getData={getData}
        is_profile_completed={data?.is_profile_completed}
        setBlur={setBlur}
      />,

    'Booking Information':
      <BuisnessInformation
        data={buisnessInformation}
        getData={getData}
        is_profile_completed={data?.is_profile_completed}

      />
  };


  return (
    <>
      {Object.keys(data)?.length === 0
        ?
        <PartyLoader />
        :
        <div className={
          data?.is_profile_completed ? 'mx-auto max-w-5xl px-1 py-0 md:px-4 md:py-4 lg:px-8 z-0 '
            : modal ? 'brightness-50 px-1 py-0 md:px-4 md:py-4  lg:px-8 mx-auto absolute top-0 left-0 w-screen h-full bg-white z-50 overflow-auto'
              : 'px-1 py-0 md:px-4 md:py-4  lg:px-8 mx-auto absolute top-0 left-0 w-screen h-full bg-white z-50 overflow-auto'
        }
        >
          <div className={modal ? 'blur-sm  opacity-70' : 'blur-none'}>
            <div className={blur ? 'blur' : 'blur-none'}>
          <div className={!data?.is_profile_completed ? 'w-11/12 lg:w-9/12 mx-auto' : ''}>
              {!data?.is_profile_completed ? 
              <Steps
              tabArray={tabs}
            />
              :
              <Tabs
              tabArray={tabs}
             changeTab={changeTab}
              />
              }
              
            </div>
            
            <div className={!data?.is_profile_completed ? 'w-11/12 lg:w-9/12 mx-auto' : ''}>
              {
                tabContent[tabName]
              }
            </div>
            </div>
          </div>
        </div>

      }

      {/* modal for tour */}
      {
        modal &&
        <Modals
          handleClose={() => setModal(false)}
        >
          <div className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-sm sm:p-6'>
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <SparklesIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Welcome to Booking Platform
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Please complete your profile to get started.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 flex justify-center">
              <PrimaryButton
                text="Go to Profile"
                onClick={() => {
                  setModal(false);
                }}

              />
            </div>
          </div>
        </Modals>
      }
    </>
  )
}
