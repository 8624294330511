import React from 'react';
import { createPortal } from 'react-dom';



const Modal = ({ children, handleClose }) => {
    const portal = document.getElementById('portal');
    
    return createPortal(
        <section
            className="fixed top-0 left-0 lg:left-12 w-screen h-screen flex justify-center items-center p-4"
            style={{
                zIndex: 999999,
            }}
        >
            <div className="relative w-full sm:w-fit h-fit z-20">{children}</div>
            <span
                className="absolute top-0 left-0 w-full h-full"
                onClick={handleClose}
            ></span>
        </section>,
        portal,
    );
};

export default Modal;