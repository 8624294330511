import React from 'react'
import { useState, useEffect} from 'react';
import Input from '../../components/atoms/input'
import { FileUploader } from "react-drag-drop-files";
import Textarea from '../../components/atoms/textarea'
import PrimaryButton from '../../components/atoms/primaryButton'
import SecondaryButton from '../../components/atoms/secondaryButton';
import { CloudArrowUpIcon, XCircleIcon } from "@heroicons/react/24/outline";
import Typeaheads from '../../components/atoms/typeaheads';
import toast from 'react-simple-toasts';
import uploadToFireBase from '../../services/uploadToFireBase'
import deleteFromFireBase from '../../services/deleteFromFirebase';
import DivLoader from '../../components/loader/divLoader';
import { debounce } from "lodash";
import Axios from '../../AxiosConfig';
export default function Personalnformation({
  data,
  getData,
  is_profile_completed
}) {
  const fileTypes = ["JPG", "PNG", "JPEG", "WEBP"];
  const [formObject, setFormObject] = useState(data);
  const [formErrors, setFormErrors] = useState({});
  const [coverLoading, setCoverLoading] = useState(false);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [delArray, setDelArray] = useState([]);
  let slug = data.slug
  // listening to changes in the fields
  const handleFieldChange = (field, value) => {
    console.log(field, value);
    setFormErrors({});
    let tempFormObject = { ...formObject };
    let fieldArray = field.split(".");
    if (fieldArray.length === 1) {
      tempFormObject[field] = value;
    } else if (fieldArray.length === 2) {
      // check if field is undefined
      if (!tempFormObject[fieldArray[0]]) {
        tempFormObject[fieldArray[0]] = {};
      }
      tempFormObject[fieldArray[0]][fieldArray[1]] = value;
    }
    setFormObject(tempFormObject);
  }
  // uploading media
  const handleChange = async (file, from) => {
    let tempUrl = URL.createObjectURL(file);
    setFormErrors({});
    if (from === 'avatar') {
      setAvatarLoading(true);
      let img = await uploadToFireBase(file,formObject.user_id);
      if (!img) {
        toast('Image Upload Failed', { duration: 3000, clickable: true, className: 'my-toast-failure', position:'top-right' });
        setAvatarLoading(false);
        return;
      }
      else {
        let tempFormObject = { ...formObject };
        tempFormObject['imageUrl'] = img;
        setFormObject(tempFormObject);
        setAvatarLoading(false);
        handleFieldChange('imageUrl', img);
        toast('Image Uploaded Successfully', { duration: 3000, clickable: true, className: 'my-toast-success', position:'top-right' });
      }
    }
    else if (from === 'cover') {
      setCoverLoading(true);
      let img = await uploadToFireBase(file, formObject.user_id);

      if (!img) {
        toast('Image Upload Failed', { duration: 3000, clickable: true, className: 'my-toast-failure' ,position:'top-right'});
        setCoverLoading(false);
        return;
      }
      else {
        let tempFormObject = { ...formObject };
        tempFormObject['coverImageUrl'] = img;
        setFormObject(tempFormObject);
        setCoverLoading(false);
        handleFieldChange('coverImageUrl', img);
        toast('Image Uploaded Successfully', { duration: 3000, clickable: true, className: 'my-toast-success' ,position:'top-right'});
      }
    }
  };

  // field Validations
  const validateFields = () => {
    let errors = {};
    if (!formObject?.coverImageUrl) {
      errors.coverImageUrl = '*Cover Image is required'
    }
    if (!formObject.imageUrl) {
      errors.imageUrl = '*Profile Image is required'
    }
    if (!formObject?.name) {
      errors.name = '*Name is required'
    }
    if (formObject?.name) {
      if (!(new RegExp(/\S.*\w/).test(formObject?.name))) {
        errors.name = '*Name should contain only alphabets'
      }
      if (formObject?.name.length < 2 || formObject?.name.length > 30) {
        errors.name = '*Name should contain 2 to 30 characters'
      }

    }
    if(!formObject?.slug){
      errors.slug = '*Slug is required'
    }
    if (!formObject?.about) {
      errors.about = '*About field is required'
    }
    if (formObject?.about) {
      if (formObject?.about.split(/[\s.,]+/).length < 10) {
        errors.about = '*About should contain atleast 10 words'
      }
      if (formObject?.about.split(/[\s.,]+/).length > 1500) {
        errors.about = '*About should contain less than 1500 words'
      }
    }

    if (!formObject.addressObj || Object.keys(formObject.addressObj).length === 0) {
      errors.addressObj = '*Address field is required';
    }
    if (!formObject.mainAddress?.address1 || formObject.mainAddress?.address1.length < 5) {
      errors['mainAddress'] = { ...errors["mainAddress"], address1: '*Address1 field is required, ' };
    }
    if(!formObject.zipCode){
      errors['zipCode'] = '*Zipcode field is required';
    }
    if(formObject.zipCode){
      if(!(new RegExp(/^\d{5}$/).test(formObject?.zipCode))){
        errors['zipCode'] = '*Zipcode should contain 5 digits';
      }
    }
    if (!formObject.locationsArr || formObject.locationsArr.length < 1) {
      errors.locationsArr = '*Please Select Atleast 1 Location';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }
  const [buttonText, setButtonText] = useState('Next');
  //change screen
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit", is_profile_completed, buttonText)
    let isValid = validateFields();
    let tempFormObject = { ...formObject };
    if (isValid ) {
      if(delArray.length > 0){
        delArray.map((item) => {
          deleteFromFireBase(item);
        })
      }
      if ('addressObj' in tempFormObject) {
        tempFormObject['address'] = tempFormObject.addressObj.label;
      }
      if ('locationsArr' in tempFormObject) {
        let tempLocations = [];
        tempFormObject.locationsArr.map((item) => {
          tempLocations.push(item.label);
        })
        tempFormObject['locations'] = tempLocations
      }
      if(buttonText === 'Next'){
      getData(tempFormObject, 'Basic Information', 'Next')
      }
      else if(buttonText === 'Save'){
        getData(tempFormObject, 'Basic Information', 'Save')
      }
    }
    
    else {
      console.log("not validate")

    }
  }
const [slugDisabled, setSlugDisabled] = useState(true);
 const createSlug = (val) => {
  if(val && val.length > 3){
    Axios.post('/onboarding/create-slug', {'slug':val}).then((res) => {
      
      if(res.data){
        if(res.data.slug !== 'exists'){
          // create a deep copy of formObject
          let tempFormObject = JSON.parse(JSON.stringify(formObject));
          tempFormObject['slug'] = res.data.slug;
          setTimeout(() => {
          setFormObject(prevState => ({
            ...prevState,
            ['slug']: res.data['slug']
          })
            );
          }, 2000);
          console.log(tempFormObject);
          setSlugDisabled(true);
        }
        else{
          toast('Slug Already Exists, ', { duration: 3000, clickable: true, className: 'my-toast-failure', position:'top-right' });
          handleFieldChange('slug', '');
          setSlugDisabled(false);
        }
      }
    } ).catch((err) => {
      console.log(err);
    })
  }
 }

  useEffect(() => {
    console.log("data", data)
    setFormObject(data);
    // console.log(data, is_profile_completed)
  }, [data])




  
 

  return (
    <>
      <main > 
        {/* Settings forms */}
        <div className="divide-y ">
          <div className="grid w-full grid-cols-1 gap-x-8 gap-y-2 px-0 py-0 lg:px-4 lg:py-4 md:px-4 md:py-4 ">
            <form  onSubmit={handleSubmit} noValidate >
              <div className={!is_profile_completed ?'mx-auto grid grid-cols-1 gap-x-6 gap-y-4 max-w-4xl sm:grid-cols-6 ' :'grid grid-cols-1 gap-x-6 gap-y-4 max-w-3xl sm:grid-cols-6 ' }>
                <div className='col-span-full'>
                  <div className=" flex items-center  w-full  landscape:w-full lg:w-full md:w-full gap-x-8 relative mt-8">
                    <span className='absolute -top-3 text-sm text-gray-900 font-semibold '>
                      Cover Image
                    </span>
                    <span className='absolute top-3 text-xs text-gray-500 font-medium'>People showcasing themselves with their business have a higher rate of booking </span>
                    <br/>
                  </div>
                  {formObject.coverImageUrl ?
                    <div className='relative p-4 bg-gray-50 rounded-lg w-56 landscape:w-full md:w-full lg:w-full mt-6 lg:mt-4 '>
                      <img
                        src={formObject.coverImageUrl}
                        alt=""
                        className="w-full h-56 flex-none rounded-lg bg-gray-800 object-cover"
                      />
                      <div className=' absolute top-0 right-0'>
                        <XCircleIcon className='h-5 w-5 text-black cursor-pointer'
                          onClick={() => {
                            handleFieldChange('coverImageUrl', '')
                            console.log(formObject.coverImageUrl);
                            let tempDelArray = [...delArray];
                            tempDelArray.push(formObject.coverImageUrl);
                            setDelArray(tempDelArray);
                          }}
                        />
                      </div>
                    </div>
                    :
                    <>
                      <div className='px-1 py-2.5 md:p-3.5 lg:p-3.5 lg:mt-0 mt-4'>
                        <FileUploader
                          handleChange={(_file) => { handleChange(_file, 'cover') }}
                          name="file"
                          style={{ width: '200px' }}
                          types={fileTypes}
                          required
                          disabled={coverLoading}
                          classes = 'w-56 lg:w-full  text-center flex-none'
                          onTypeError={(err) => {
                            toast('Extension Not Supported', { duration: 3000, clickable: true, className: 'my-toast-failure', position:'top-right' });
                          }}
                        >

                          <div className="relative w-full lg:w-full h-56 text-center flex-none rounded-lg border-dashed border-2 border-blue-600 cursor-pointer" >
                            {!coverLoading
                              ?
                              <>
                                <CloudArrowUpIcon className="h-12 w-12 text-blue-600 mx-auto mt-16" />
                                <div className="text-blue-600 text-xs  font-semibold">Upload Your Cover Image</div>
                              </>
                              :
                              <div class="absolute w-12 h-12 m-auto left-0 right-0 top-0 bottom-0 flex items-center">
                                <DivLoader />
                              </div>
                            }
                          </div>


                        </FileUploader>
                      </div>
                      <p className="text-red-500 text-sm font-semibold  ml-4"> {formErrors.coverImageUrl}</p>
                    </>
                  }
                </div>
                
                <div className="col-span-full ">
                <div className=" flex items-center gap-x-8 w-64 landscape:w-full lg:w-full md:w-full relative mt-4 ">
                  <span className='absolute -top-3 text-sm text-gray-900 font-semibold'>Profile Image</span>
                  <span className='absolute top-3 w-full text-xs text-gray-500 font-medium'>This will be your primary image, visible to all customers</span>
                  <br/>
                </div>
                  {formObject.imageUrl ?
                    <div className='relative p-4 bg-gray-50 rounded-lg w-56 left-0 lg:left-0 mt-6 lg:mt-4'>
                      <img
                        src={formObject.imageUrl}
                        alt=""
                        className="h-44 w-56 flex-none rounded-lg bg-gray-800 object-cover"
                      />
                      <div className=' absolute top-0 right-0'>
                        <XCircleIcon className='h-5 w-5 text-black cursor-pointer' onClick={() => {
                          handleFieldChange('imageUrl', '')
                          let tempDelArray = [...delArray];
                          tempDelArray.push(formObject.imageUrl);
                          setDelArray(tempDelArray);

                        }} />
                      </div>
                    </div>
                    :
                    <div className='px-1 py-2.5 md:p-3.5 lg:p-3.5 lg:mt-0 mt-4 w-fit'>
                      <FileUploader
                        handleChange={(_file) => { handleChange(_file, 'avatar') }}
                        name="file"
                        types={fileTypes}
                        required
                        disabled={avatarLoading}
                        
                        onTypeError={(err) => {
                          toast('Extension Not Supported', { duration: 3000, clickable: true, className: 'my-toast-failure', position:'top-right' });
                        }}
                      >

                        <div className="h-44 w-44 relative  flex-none rounded-lg items-center border-dashed border-2 border-blue-600 cursor-pointer text-center" >
                          {!avatarLoading
                            ?
                            <>
                              <CloudArrowUpIcon className="h-12 w-12 text-blue-600 mx-auto mt-10" />
                              <div className="text-blue-600 text-xs  font-semibold">Upload Your Profile Image</div>
                            </>
                            :
                            <div class="absolute w-7 h-7 m-auto left-0 right-0 top-0 bottom-0 flex items-center">
                              <DivLoader />
                            </div>
                          }
                        </div>
                      </FileUploader>
                      <p className="text-red-500 text-sm font-semibold mt-2"> {formErrors.imageUrl}</p>
                    </div>
                  }

                </div>
                <div className="col-span-3 sm:col-span-full md:col-span-full lg:col-span-full mt-1 md:mt-3 lg:mt-3 w-full">
                  <Input
                    label="Business Name"
                    name="name"
                    placeholder="Enter your business name"
                    value={formObject.name}
                    onChange={
                      (value) => handleFieldChange('name', value)
                    }
                    onBlur={
                      (value)=> createSlug(value)
                    }
                    msgText={"If you don't have a business name, enter your full name"}
                  />
                  <span className="text-red-500 text-sm font-semibold"> {formErrors.name}</span>
                </div>
                <div className="col-span-3 sm:col-span-full md:col-span-full lg:col-span-full mt-1 md:mt-3 lg:mt-3 w-full">
                  <Input
                    label="Slug"
                    name="slug"
                    placeholder="Create your slug"
                    value={formObject.slug}
                    isDisabled={slugDisabled}
                    onChange={
                      (value) =>{
                        createSlug(value);
                      }
                    }
                    msgText={"This will be used in your profile URL"}
                  />
                 <span className="text-red-500 text-sm font-semibold"> {formErrors.slug}</span>
                </div>
                <div className="col-span-3 sm:col-span-full md:col-span-full lg:col-span-3  mt-1 md:mt-3 lg:mt-0">
                  <Input
                    label="Work Email"
                    placeholder="Enter your Email"
                    name="email"
                    isDisabled={true}
                    value={formObject.email}
                    onChange={(value) =>
                      handleFieldChange('email', value)
                    }
                    msgText={'This email will be used for all communication'}
                  />
                  
                </div>
                <div className="col-span-3 sm:col-span-full md:col-span-full lg:col-span-3 mt-0 md:mt-7 lg:mt-4">
                  <Input
                    label="Mobile Number"
                    placeholder="Enter your Mobile Number"
                    value={formObject.phone}
                    isDisabled={true}
                    addOn={true}
                    addOnText='+1'
                    onChange={(value) =>
                      handleFieldChange('phone', value)
                    }
                    
                  />
                  <span className="text-red-500 text-sm font-semibold"> {formErrors.phone}</span>
                </div>
                <div className="col-span-3 sm:col-span-full lg:col-span-full md:col-span-full ">
                  <Textarea
                    label="Tell us about your business"
                    placeholder={`${formObject.name} is a ...`}
                    value={formObject.about}
                    onChange={(value) =>
                      handleFieldChange('about', value)
                    }
                    msgText='This will be visible to all customers and will help them understand your services better'
                  />
                  <span className="text-red-500 text-sm font-semibold"> {formErrors.about}</span>
                </div>
                <div className="col-span-3">
                  <Input
                    label="Address 1"
                    placeholder="Enter your Address"
                    name="address1"
                    isOptional={true}
                    value={formObject?.mainAddress?.address1}
                    onChange={(value) =>
                      handleFieldChange('mainAddress.address1', value)
                    }
                  />
                  <span className="text-red-500 text-sm font-semibold"> {formErrors?.mainAddress?.address1}</span>
                </div>
                <div className="col-span-3">
                  <Input
                    label="Address 2"
                    placeholder="Enter your Adresss"
                    name="address2"
                    value={formObject.mainAddress?.address2}
                    isOptional={true}
                    optionalText='Optional'
                    onChange={(value) =>
                      handleFieldChange('mainAddress.address2', value)
                    }
                  />
                  <span className="text-red-500 text-sm font-semibold"> {formErrors.mainAddress?.address2}</span>
                </div>
                <div className="col-span-3 sm:col-span-full lg:col-span-full md:col-span-full">
                  <Input
                    label="ZipCode"
                    placeholder="Enter your ZipCode"
                    name="zipcode"
                    value={formObject?.zipCode}
                    onChange={(value) =>
                      handleFieldChange('zipCode', value)
                    }
                  />
                  <span className="text-red-500 text-sm font-semibold"> {formErrors?.zipCode}</span>
                </div>
                <div className="col-span-3 sm:col-span-full lg:col-span-full md:col-span-full">
                  <p className="text-sm font-medium leading-6 text-gray-900 mb-4"> Current City</p>
                  <Typeaheads
                    onChange={(value) => {
                      debounce(() => {
                        handleFieldChange('addressObj', value)
                      }, 500)();
                    }}
                    apiURL={'/locations/get-locations'}
                    defaultValue={formObject?.addressObj}
                    filterArray={formObject?.locationsArr}
                    placeholder="Denver, Aurora, Boulder, etc."
                  />
                  <span className="text-red-500 text-sm font-semibold"> {formErrors.addressObj}</span>
                </div>
                <div className="col-span-3 sm:col-span-full lg:col-span-full md:col-span-full">
                  <div className="flex flex-col mb-2 ">
                  <p className="text-sm font-medium  text-gray-900 ">Other Cities </p>
                  <span className="text-xs font-medium  text-gray-500 ">Other cities { formObject.addressObj && "apart from"} {formObject?.addressObj?.label} 
                                                                                    {" "} where you offer your services, you can select upto 5 cities</span>
                  </div>
                  <Typeaheads
                    onChange={(value) => {
                      debounce(() => {
                        handleFieldChange('locationsArr', value)
                      }, 500)();
                    }}
                    isMulti={true}
                    // disabled={formObject.locationsArr?.length >= 5 ? true : false}
                    filterOption={formObject?.addressObj}
                    isDisable={formObject?.addressObj ? false : true}
                    apiURL={'/locations/get-locations'}
                    defaultValue={formObject?.locationsArr}
                    placeholder="Select Other Cities"
                  />
                  { !formObject.addressObj &&
                  <span className="text-red-500 text-sm font-semibold"> *Disabled! Please Select City first </span>
                   }
                   {(formObject.locationsArr?.length >= 5 && formObject.addressObj)&&
                    <span className="text-red-500 text-sm font-semibold"> *You can select upto 5 cities </span>
                   }
                  <span className="text-red-500 text-sm font-semibold"> {formErrors.locationsArr}</span>
                </div>
                <div className="mt-2 mb-8 text-center col-span-full ml-8">
                <PrimaryButton
                  type="submit"
                  text={is_profile_completed ? 'Save' : 'Next'}
                  
                  onClick={() => {
                    is_profile_completed ? setButtonText('Save') : setButtonText('Next');
                  }}
                />
              </div>
              </div>
            </form>
          </div>
        </div>
      </main>

    </>


  )
}
