import React, { useState, useEffect } from 'react';

import AsyncSelect from 'react-select/async';
import Axios from '../../AxiosConfig';

import { components } from 'react-select';

                

const Typeaheads = ({ apiURL, defaultValue = undefined,onChange,placeholder,isMulti=false,isDisable=false,filterOption= {},filterArray=[]}) => {
    const [value, setValue] = useState("");
    const [defaultUserValue, setDefaultUserValue] = useState(defaultValue ? defaultValue : {});
    const getResults = async (inputValue) => {
        return Axios.post(apiURL, { value: inputValue }).then(data => {
            return data.data.map((d) => ({ label: d?.display_name ? d.display_name : d.name, value: d.slug}));
        });
    }
    const customStyles = {
        control: styles => ({ ...styles, backgroundColor: 'rgb(249 250 255)' , fontSize:'0.875rem' , borderRadius:'8px',
                            minHeight:'42px' , borderColor:'rgb(209 213 219)', }),
        
        // in  options add border styles
        
        option: (styles, 
            {  isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled
                ? null
                : isSelected
                ? '#F9FAFB'
                : isFocused
                ? 'rgb(219 234 254)'
                : null,
                color: isDisabled
                ? '#ccc'
                : isSelected
                ? '#161e2e'
                : isFocused
                ? '#161e2e'
                : null,
                cursor: isDisabled ? 'not-allowed' : 'default',
                fontSize:'0.875rem',
                padding : '16px',
                cursor: 'pointer',

                
            };
        },

        multiValue: (styles,) => {
            return {
                ...styles,
                backgroundColor: 'rgb(219 234 254)',
                fontSize:'1rem',
                color: 'red',
                borderRadius: '5px',
                padding : '1px',
                display:'flex',
                alignItems:'center',
               

            };
        },
        multiValueLabel: (styles) => ({
            ...styles,
            color: 'rgb(29 78 216)',
            fontSize:'14px',
          }),
         
        multiValueRemove: (styles) => ({
            ...styles,
            color: 'red',
            fontSize:'18px',
            height :'20',
            width:'20',
            backgroundColor: 'rgb(219 234 254)',
          }),

      }
      const [limit , setLimit] = useState(0)
      useEffect(() => {
        if (defaultValue) {
            setDefaultUserValue(defaultValue);
            if(isMulti){
                setLimit(defaultValue.length)
            }
            
        }

    }, [defaultValue]);
   
    const MultiValueRemove = (props) => {
        return (
          <components.MultiValueRemove {...props}>
            <div className='text-xl font-bold lg:text-base lg:font-bold '> &times;</div>
          </components.MultiValueRemove>
        );
      };
   
    
    return (
        <AsyncSelect
            isClearable
            cacheOptions
            loadOptions={getResults}
            placeholder={placeholder}
            defaultOptions
            onChange={(option) =>{
               onChange(option)

            }
            }
            components={{ MultiValueRemove }}
            isDisabled={isDisable}
            isMulti = {isMulti}
            isOptionDisabled={(option) => {
                if(limit >=5){
                    return true;
                }
                if(!isMulti && filterArray.length > 0){
                    return (filterArray.filter((f) => f.value === option.value).length > 0)
                }
                else{
                    return (option.value === filterOption?.value)
                }
            }}
            
            menuPlacement="auto"
            defaultValue={
                isMulti ? (defaultUserValue?.length > 0 ? (defaultUserValue?.map((d) => ({ label: d.label, value: d.value}))) : null) 
                : (defaultUserValue?.label ? { label: defaultUserValue.label, value: defaultUserValue.value } : null)
            }
           
             styles={customStyles}
                       
            
        />
    )
}
export default Typeaheads;