const PrimaryButton = ({ text, disbabled, onClick,classNames,type="button" }) =>{
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disbabled}
      className= {`rounded-md bg-blue-700 px-8 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
       focus-visible:outline-neutral-600  ${classNames}`}
    >
      {text}
    </button>
  );
}
export default PrimaryButton;
