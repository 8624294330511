const Textarea = ({ onChange, msgText='',placeholder, value = "", rows = 4 ,label}) => {
  return (
    <>
    <div className="flex">
    <label
        htmlFor="default-input"
        className="block mb-0.5 text-sm font-medium text-gray-900"
      >
        {label}
      </label>
    </div>
    {msgText.length > 0 &&
      <div className="flex">
        <label
          htmlFor="default-input"
          className="block  text-xs font-medium text-gray-500"
        >
         {msgText}
        </label>
      </div>
     }
    <textarea
      rows={rows}
      defaultValue={value}
      onChange={(e) => onChange(e.target.value)}
      required
      className="block mt-2 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
      placeholder={placeholder ? placeholder : ""}
    ></textarea>
    </>
  );
}
export default Textarea;
