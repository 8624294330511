import { firebaseStorage } from "../lib/firebase";
import { ref, getDownloadURL, uploadBytes, getStorage } from "firebase/storage";

const uploadToFireBase = async (file,uid) => {
  const storageRef = ref(firebaseStorage, `media/${uid}/${file.name}`);
  try {    
    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(snapshot.ref);
    return url;

  }
  catch (err) {
    console.log(err)
  }
}

export default uploadToFireBase;
