import React from 'react'
import { useState, useEffect } from 'react';
import DropDown from '../../components/atoms/select';
import Input from '../../components/atoms/input';
import CheckBox from '../../components/atoms/checkbox';
import { debounce } from "lodash";
import Typeaheads from '../../components/atoms/typeaheads';
import PrimaryButton from '../../components/atoms/primaryButton';
import SecondaryButton from '../../components/atoms/secondaryButton';
export default function BuisnessInformation({ data, getData, is_profile_completed }) {
  const [formObject, setFormObject] = useState(data);
  const [formErrors, setFormErrors] = useState({});
  const [buttonText, setButtonText] = useState('');
  const [isNationWide, setIsNationWide] = useState(formObject?.canTravelNationwide? formObject?.canTravelNationwide : false);
  // listening to changes in the fields
  const handleFieldChange = (field, value) => {
    setFormErrors({});
    let tempFormObject = { ...formObject };
    let fieldArray = field.split(".");
    if (fieldArray.length === 1) {
      tempFormObject[field] = value;
    } else if (fieldArray.length === 2) {
      if (!tempFormObject[fieldArray[0]]) {
        tempFormObject[fieldArray[0]] = {};
      }
      tempFormObject[fieldArray[0]][fieldArray[1]] = value;
    } else if (fieldArray.length === 3) {
      tempFormObject[fieldArray[0]][fieldArray[1]][fieldArray[2]] = value;
    }
    console.log(formObject);
    setFormObject(tempFormObject);

  }
  // validate fields before submitting
  const validateFields = () => {
    let errors = {};
    if (!formObject.charges?.amount) {
      errors["charges"] = { ...errors["charges"], amount: "*Please enter amount." };
    }
    if (isNaN(formObject.charges?.amount)) {
      errors["charges"] = { ...errors["charges"], amount: "*Please enter valid amount." };
    }
    // if (!formObject.charges?.per) {
    //   errors["charges"] = { ...errors["charges"], per: "*Please select hours/event." };
    // }
    if (!formObject.categoryObj || Object.keys(formObject.categoryObj).length === 0) {
      errors["categoryObj"] = "*Please select primary category.";
    }
    if (!formObject.servicesArr || formObject.servicesArr.length < 1) {
      errors["servicesArr"] = "*Please select other categories.";
    }
    if ((!formObject.travelRange || formObject.travelRange.length < 2)&& !isNationWide) {
      errors["travelRange"] = "*Please enter travel range.";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }
  // submit form
  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = validateFields();
    let tempFormObject = { ...formObject };


    if (buttonText === 'Back') {
      if ('servicesArr' in tempFormObject && tempFormObject?.servicesArr !== undefined) {
        let tempServices = [];
        tempFormObject?.servicesArr.map((item) => {
          tempServices.push(item.label);
        });
        tempFormObject['services'] = tempServices;
      }
      else{
        tempFormObject['services'] = [];
      }
      if ('categoryObj' in tempFormObject && tempFormObject?.categoryObj !== null) {
        tempFormObject['category'] = tempFormObject.categoryObj.label;
      }
      else{
        tempFormObject['category'] = '';
      }
      getData(tempFormObject, 'Booking Information', 'Back')
    }
    if (isValid && buttonText === 'Submit') {
      tempFormObject['charges']['amount'] = parseInt(tempFormObject['charges']['amount']);
      if(isNationWide){
        tempFormObject['travelRange'] = 0;
      }
      else{
        tempFormObject['travelRange'] = parseInt(tempFormObject['travelRange']);
      }
      if ('servicesArr' in tempFormObject && tempFormObject?.servicesArr.length > 0) {
        let tempServices = [];
        tempFormObject?.servicesArr.map((item) => {
          tempServices.push(item.label);
        });
        tempFormObject['services'] = tempServices;
      }
      else{
        tempFormObject['services'] = [];
      }
      if ('categoryObj' in tempFormObject && Object.keys(tempFormObject?.categoryObj).length > 0)  {
        tempFormObject['category'] = tempFormObject.categoryObj.label;
      }
      else{
        tempFormObject['category'] = '';
      }
      getData(tempFormObject, 'Booking Information', 'Submit',)
    }
    if(isValid && buttonText === 'Save'){
      tempFormObject['charges']['amount'] = parseInt(tempFormObject['charges']['amount']);
      if(isNationWide){
        tempFormObject['travelRange'] = 0;
      }
      else{
        tempFormObject['travelRange'] = parseInt(tempFormObject['travelRange']);
      }
      if ('servicesArr' in tempFormObject && tempFormObject?.servicesArr.length > 0) {
        let tempServices = [];
        tempFormObject?.servicesArr.map((item) => {
          tempServices.push(item.label);
        });
        tempFormObject['services'] = tempServices;
      }
      else{
        tempFormObject['services'] = [];
      }
      if ('categoryObj' in tempFormObject && Object.keys(tempFormObject?.categoryObj).length > 0)  {
        tempFormObject['category'] = tempFormObject.categoryObj.label;
      }
      else{
        tempFormObject['category'] = '';
      }
      getData(tempFormObject, 'Booking Information', 'Save',)
    }

  }
  useEffect(() => {
    console.log(data);
    if (data) {
      setFormObject(data);
    }
   
  }, [data]);



  return (
    <form onSubmit={handleSubmit}>

      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900 flex flex-col relative">
              <div className='static md:absolute lg:absolute top-0 lg:top-6 md:top-2'>
              <p className='px-2'>Charges</p>
              <p className='text-xs text-gray-500 font-medium px-2 '> 
                 Provide information about your hourly or event-based service charges
              </p>
              </div>
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex">
              <div className='p-2'>
                <Input
                  label='Amount'
                  placeholder='Enter Amount'
                  value={formObject.charges?.amount}
                  onChange={(value) =>
                    handleFieldChange('charges.amount', value)
                  }
                  type= 'number'
                  addOn={true}
                  addOnText='$'
                />
                <span className="text-red-500 text-sm font-semibold"> {formErrors.charges?.amount}</span>
              </div>
              <div className='p-2 mt-0.5'>
                <DropDown
                  label='Hour/Event'
                  options={['Hour', 'Event']}
                  defaultValue={formObject.charges?.per ? formObject.charges?.per : 'Hour'}
                  onChange={(value) =>
                    handleFieldChange('charges.per', value)
                  }
                />
                <span className="text-red-500 text-sm font-semibold"> {formErrors.charges?.per}</span>
              </div>
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900 flex flex-col ">
              <div className=''>
              <p className='px-2'>Travel Range</p>
              <p className='text-xs text-gray-500 font-medium px-2'> 
                Provide information about your travel range
              </p>
              </div>
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-col">
            <div className='p-2 w-fit'>
              <CheckBox label='Throughout the Country' 
               checked={isNationWide}
                onChange={(value) => {
                  console.log(value);
                  setIsNationWide(value);
                  handleFieldChange('canTravelNationwide', value); 
                }
                }
               />
              </div>
              <div className='p-2'>
              <Input
                label='Travel Range'
                type='number'
                placeholder='Enter Travel Range'
                value={isNationWide? '0': formObject.travelRange}
                isDisabled={isNationWide}
                onChange={(value) =>
                  handleFieldChange('travelRange', value)
                }
              />
              </div>
              <span className="text-red-500 text-sm font-semibold "> {formErrors?.travelRange}</span>
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900 flex flex-col ">
              <div className=''>
              <p className='px-2'>Primary Category</p>
              <p className='text-xs text-gray-500 font-medium px-2'> 
                The category that best describes your services.
              </p>
              </div>
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">

              <Typeaheads
                onChange={(value) =>
                  debounce(() => {
                    handleFieldChange('categoryObj', value)
                  }, 500)()
                }
                apiURL={'/categories/get-categories'}
                defaultValue={formObject.categoryObj}
                placeholder="Singer, DJ, Photographer, etc."
                filterArray={formObject?.servicesArr}
              />
              <span className="text-red-500 text-sm font-semibold "> {formErrors.categoryObj}</span>
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900 flex flex-col ">
              <div>
              <p className='px-2'>Other Categories</p>
              <p className='text-xs text-gray-500 font-medium px-2'> 
              Other categories { formObject.categoryObj && "apart from"} {formObject?.categoryObj?.label} 
                                                                                    {" "} which best describe your services 
                                                                                    , you can select upto 5 categories
              </p>
              </div>
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <Typeaheads
                onChange={(value) =>
                  debounce(() => {
                    handleFieldChange('servicesArr', value)
                  }, 500)()
                }
                isMulti={true}
                filterOption={formObject?.categoryObj}
                isDisable={formObject?.categoryObj ? false : true}
                apiURL={'/categories/get-categories'}
                defaultValue={formObject.servicesArr}
                placeholder="Select Other Categories"
              />
              {!formObject.categoryObj &&
                <span className="text-red-500 text-sm font-semibold"> *Disabled! Please Select Category first </span>
              }
              {(formObject.servicesArr?.length >= 5 && formObject.categoryObj)&&
                    <span className="text-red-500 text-sm font-semibold"> *You can select upto 5 categories </span>
                   }
              <span className="text-red-500 text-sm font-semibold "> {formErrors.servicesArr}</span>
            </dd>
          </div>
        </dl>
      </div>
      <div className="mt-8 flex justify-center">
        {!is_profile_completed &&
        <SecondaryButton
          type="submit"
          text="Back"
          onClick={() => {
            setButtonText('Back');
          }}
          
        />
        }
        <PrimaryButton
          type="submit"
          text={is_profile_completed ? 'Save' : 'Submit'}
          classNames={"ml-4"}
          onClick={() => {
            is_profile_completed ? setButtonText('Save') : setButtonText('Submit');
          }}
        />
      </div>
    </form>

  )
}
