import React, { useCallback, useRef } from 'react'
import { PlusCircleIcon, XCircleIcon, PencilIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from 'react';
import { FileUploader } from "react-drag-drop-files";
import uploadToFireBase from '../../services/uploadToFireBase';
import deleteFromFireBase from '../../services/deleteFromFirebase';

import PrimaryButton from '../../components/atoms/primaryButton';
import SecondaryButton from '../../components/atoms/secondaryButton';
import toast from 'react-simple-toasts';
import DivLoader from '../../components/loader/divLoader';
import Modals from '../../components/molecules/modals';
import Input from '../../components/atoms/input';
import Textarea from '../../components/atoms/textarea';
import ReactPlayer from 'react-player/lazy'
export default function Media({ data = [], getData, is_profile_completed, setBlur }) {
  const [formObject, setFormObject] = useState(data);

  const fileTypes = ['MP4', 'JPG', 'PNG', 'JPEG', 'WEBP', 'MOV']
  const [media, setMedia] = useState(data?.media || []);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [modal, setModal] = useState(false);
  const [backGround, setBackGround] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [delArray, setDelArray] = useState([])




  const handleChange = async (file) => {
    let tempMedia = [...media];
    setLoading(true);

    let isVideo = file.type.split('/')[0] === 'video' ? true : false;
    const mediaFile = await uploadToFireBase(file, formObject.user_id);
    if (!mediaFile) {
      setLoading(false);
      toast(' Upload Failed', { duration: 3000, clickable: true, className: 'my-toast-failure', position: 'top-right' });
    }
    else {
      if (isVideo) {
        tempMedia.push({ url: mediaFile, title: '', description: '', media_type: 'video' });
      }
      else {
        tempMedia.push({ url: mediaFile, title: '', description: '', media_type: 'image' });
      }
      let index = tempMedia.findIndex((item) => item.url === mediaFile);
      openModal(index);
      setMedia(tempMedia);
      //setFormObject({ ...formObject, images: tempMedia });
      setFormObject((prev) => {
        return { ...prev, media: tempMedia }
      })
      setLoading(false);
      setFormErrors({});
      setShowCancel(true);
      setBlur(true)
      if (tempMedia.length > 11) {
        setIsDisabled(true);
      }
      else {
        setIsDisabled(false);
      }
      console.log("media", tempMedia);
      toast('Uploaded Successfully', { duration: 3000, clickable: true, className: 'my-toast-success', position: 'top-right' });
    }

  }
  // validate fields
  const validateFields = () => {
    let errors = {};
    if (media.length === 0) {
      errors["media"] = "*Please upload atleast one media file";
    }
    // check for empty title and descriptions
    media.map((item) => {
      if (!new RegExp(/\S.*\w/).test(item.title)) {
        errors["modalTitle"] = "*Title is too short";
        errors["media"] = "*Title is too short"
        setBackGround(true)
      }

      if (!new RegExp(/\S.*\w/).test(item.description)) {
        errors["modalDescription"] = "*Description is too short";
        errors["media"] = "*Title is too short"
        setBackGround(true)
      }

      if (!item.title) {
        errors["modalTitle"] = "*Please enter title";
        errors["media"] = "*Title is too short"
        setBackGround(true)
      }
      if (!item.description) {
        errors["modalDescription"] = "*Please enter description";
        errors["media"] = "*Title is too short"
        setBackGround(true)
      }
    })
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }
  // submit form
  const handleSubmit = (e) => {
    e.preventDefault();
    if (delArray.length > 0) {
      delArray.map((item) => {
        deleteFromFireBase(item.url);
      })
    }
    let isValid = validateFields();
    if (isValid && buttonText === 'Next') {
      getData({ 'media': media }, 'Media', 'Next')
    }
    else if (buttonText === 'Back') {
      getData({ 'media': media }, 'Media', 'Back')
    }
    else if (isValid && buttonText === 'Save') {
      getData({ 'media': media }, 'Media', 'Save')
    }
    else {
      console.log("error", media)
    }
    setDelArray([]);
  }

  useEffect(() => {
    setFormObject(data);
    if (data.media.length > 11) {
      setIsDisabled(true);
    }
  }, [data]);



  const [modalIndex, setModalIndex] = useState(0);
  const [showCancel, setShowCancel] = useState(false);
  const openModal = (id) => {
    setModalIndex(id);
    setModal(true);

  }
  // edit image title and description
  const editImage = (id, field, value) => {
    let tempMedia = [...media];
    setFormErrors({});
    if (field == 'title') {
      tempMedia[modalIndex].title = value;
      setMedia(tempMedia);
      setFormObject({ ...formObject, media: tempMedia });
    }

    else {
      tempMedia[modalIndex].description = value;
      setMedia(tempMedia);
      setFormObject({ ...formObject, media: tempMedia });
    }
  }


  return (
    <>
      {
        !loading ?
          <form onSubmit={handleSubmit} >
            <ul role="list" className="grid gap-y-8 grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-x-8 w-full ">
              <div className='p-2 mt-0 md:mt-4 lg:mt-4'>
                <div className='p-3.5 lg:p-3.5 md:p-0'
                >
                  <FileUploader
                    name="file"
                    types={fileTypes}
                    handleChange={(_file,
                    ) => {
                      console.log("file", _file.type);
                      handleChange(_file)
                    }}
                    maxSize={200}
                    onSizeError={(err) => {
                      console.log("xy", err);
                      toast('File Size should be less than 200 MB', { duration: 3000, clickable: true, className: 'my-toast-failure', position: 'top-right' });
                    }}
                    disabled={isDisabled}
                    onTypeError={(err) => {
                      console.log("ab", err);
                      toast(`Extension Not Supported , Supported Extension  ${fileTypes.map((type) => ` .${type}`)}`, { duration: 5000, clickable: true, className: 'my-toast-failure', position: 'top-right' });
                    }}
                  >
                    <div className="h-48 w-48 md:w-full lg:w-full rounded-lg items-center border-dashed border-2 border-blue-600 cursor-pointer text-center mx-auto"
                      onClick={() => {
                        if (isDisabled) {
                          toast('You can upload only 12 media files', { duration: 3000, clickable: true, className: 'my-toast-failure', position: 'top-right' });
                          return
                        }
                      }}
                    >
                      <PlusCircleIcon className="h-12 w-12 text-blue-600 mx-auto mt-14  " />
                      <div className="text-blue-600 text-xs  font-semibold px-2">Add Your Media</div>
                      <div className="text-blue-600 text-xs  font-semibold ">Uploads Left:
                        <span className={`${media.length > 6 ? 'text-red-500' : 'text-green-500'}`}> {12 - media.length}</span>
                      </div>
                      <br />
                    </div>
                  </FileUploader>

                </div>
              </div>
              {media?.map((file, id) => (
                <li key={id} className={`${(!file.title || !file.description || file.title.length > 50 || file.description.length > 100) ? 'bg-red-200' : 'bg-gray-50'} relative rounded-lg group flex items-center justify-center 
                p-5 md:p-3.5 lg:p-3.5 w-48 md:w-full lg:w-full mx-auto mt-0 md:mt-2 lg:mt-4  `}>
                  {file?.title &&
                    <div className='flex absolute top-1 lg:top-0 md:top-0 justify-center w-[100px] '>
                      <p className='text-gray-900 text-semibold text-sm mb-2 line-clamp-1'>{file.title}</p>
                    </div>
                  }
                  {(file?.media_type && file?.media_type === 'video') ?
                    <div className=" w-48 h-48 mb-2 mt-4  " >
                      <ReactPlayer url={file.url}
                        className='react-player'
                        width='100%'
                        height='100%'
                        controls={true}
                      />
                    </div>

                    :
                    <img src={file.url} alt="" className="h-48 w-48 md:w-full lg:w-full mb-2 mt-2 flex-none rounded-lg bg-gray-800 object-cover " />

                  }
                  <div className='flex absolute bottom-0 justify-center  '>
                    <p className=' text-blue-700 cursor-pointer text-sm lg:text-xs md:text-xs mb-0 lg:mb-1'
                      onClick={() => {
                        openModal(id);
                        validateFields();
                        setShowCancel(false)
                        setBlur(true)
                      }}
                    >Edit </p>
                    <p className=' text-red-700 cursor-pointer  lg:text-xs md:text-xs ml-2 text-sm mb-0 lg:mb-1 ' onClick={() => {
                      console.log("delete", file);
                      // push file to deleteImages array

                      let tempDelArray = [...delArray];
                      tempDelArray.push(file);
                      setDelArray(tempDelArray);
                      let tempMedia = [...media];
                      tempMedia.splice(media.indexOf(file), 1);
                      setMedia(tempMedia);
                      setFormObject({ ...formObject, media: tempMedia });
                      setBlur(false)
                      if (tempMedia.length < 12) {
                        setIsDisabled(false);
                      }

                    }
                    } > Delete</p>
                  </div>


                </li>
              ))}
            </ul>
            <div className='flex justify-center'>
              <span className="text-red-500 text-sm mt-2">{formErrors["media"]}</span>
            </div>
            <div className="mt-8 flex justify-center">
              {!is_profile_completed &&
                <SecondaryButton
                  type="submit"
                  text="Back"
                  onClick={() => {
                    setButtonText('Back');
                  }}

                />
              }
              <PrimaryButton
                type="submit"
                text={is_profile_completed ? 'Save' : 'Next'}
                classNames={"ml-4"}
                onClick={() => {
                  is_profile_completed ? setButtonText('Save') : setButtonText('Next');
                }}

              />
            </div>
          </form>
          :
          <div className='flex items-center justify-center h-96 '>
            <DivLoader />
          </div>
      }
      {/* modal content */}
      {
        modal &&
        <Modals
          handleClose={() => {
            if (validateFields()) {
              setModal(false)
              setBlur(false)
            }
            else {
              setModal(true)
              setBlur(true)
              return

            }
          }}
        >
          <div className='flex flex-col w-full  lg:w-96 border-1 border-gray-700 p-4 rounded-lg bg-white shadow-lg items-center  '>
            {(media[modalIndex]?.media_type && media[modalIndex].media_type === 'video') ?
              <div className="h-16 w-16 block rounded-lg bg-gray-800 object-cover mx-auto">
                <ReactPlayer
                  light={true}
                  url={media[modalIndex].url}
                  className='react-player'
                  width='100%'
                  height='100%'
                />
              </div>
              :
              <img src={media[modalIndex].url} alt="" className="h-16 w-16 block rounded-lg bg-gray-800 object-cover mx-auto" />
            }

            <div className=' p-2 mt-2 w-full'>
              <Input
                label='Caption'
                placeholder='Enter your image caption'
                value={media[modalIndex].title}
                onChange={(value) => {
                  editImage(modalIndex, 'title', value);
                }}
              />
              <p className="text-red-500 text-sm font-semibold  mt-1"> {formErrors.modalTitle}</p>
              {/* {
                (media[modalIndex].title.length === 0 || media[modalIndex].title.length > 50) &&
                <p className="text-red-500 text-sm font-semibold  mt-1"> {formErrors.modalTitle}</p>
              } */}
            </div>
            <div className='w-full p-3 mt-2'>
              <Textarea
                label='Explain the moment in your photo'
                placeholder='Performing in a wedding in Denver Colorado in front of...'
                value={media[modalIndex].description}
                onChange={(value) => {
                  editImage(modalIndex, 'description', value);
                }}
              />
              <p className="text-red-500 text-sm font-semibold mt-1"> {formErrors.modalDescription}</p>
            </div>
            <div className='flex justify-center mt-2'>
              <PrimaryButton
                type="submit"
                text="Save"
                onClick={() => {
                  if (validateFields()) {
                    setModal(false)
                    setBlur(false)
                  }
                  else {
                    setModal(true)
                    setBlur(true)
                    return

                  }
                }}
              />
              {
                showCancel ?
                  <SecondaryButton
                    type="submit"
                    text="Cancel"
                    onClick={() => {
                      let tempMedia = [...media]
                      tempMedia.splice(modalIndex, 1);
                      setMedia(tempMedia);
                      setFormObject({ ...formObject, media: tempMedia });
                      setModal(false)
                      setBlur(false)
                      setShowCancel(false)
                      deleteFromFireBase(media[modalIndex].url);
                      if (tempMedia.length < 12) {
                        setIsDisabled(false);
                      }
                    }}
                    classNames='ml-2 ring-red-500 ring-1 text-red-500 hover:bg-red-500 hover:text-white'
                  />
                  :
                  <SecondaryButton
                    type="submit"
                    text="Cancel"
                    onClick={() => {
                      if (validateFields()) {
                        setModal(false)
                        setBlur(false)
                      }
                      else {
                        setModal(true)
                        setBlur(true)
                        return

                      }
                    }}
                    classNames='ml-2 ring-red-500 ring-1 text-red-500 hover:bg-red-500 hover:text-white'

                  />
              }
            </div>
          </div>

        </Modals>
      }

    </>
  )
}
