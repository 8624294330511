import React, { useState, useEffect, useRef } from 'react';
import PrimaryButton from '../../components/atoms/primaryButton';
import Textarea from '../../components/atoms/textarea'
import Axios from '../../AxiosConfig'
import toast from 'react-simple-toasts';
import PartyLoader from '../../components/loader/partyLoader'
import { useNavigate, useLocation} from 'react-router-dom';
export default function Faqs() {
    const location = useLocation();
    
    const handleChange = (value, obj) => {
        setIsSaved(false)
        let faqs = [...faqsData]
        let index = faqs.findIndex((faq) => faq.question_id == obj.question_id)
        faqs[index].answer = value
        setFaqs(faqs)
        let updatedFaqsdata = [...updatedFaqs]
        let updatedIndex = updatedFaqsdata.findIndex((faq) => faq.question_id == obj.question_id)
        if (updatedIndex > -1) {
            console.log("found")
            updatedFaqsdata[updatedIndex].answer = value
        }
        else {
            updatedFaqsdata.push(obj)
        }
        setUpdatedFaqs(updatedFaqsdata)
    }
    const [faqsData, setFaqs] = useState([]);
    const [updatedFaqs, setUpdatedFaqs] = useState([]);
    const [completedQuestions, setCompletedQuestions] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const getQuestions = async () => {
        setLoading(true)
        let question = await Axios.get('/faqs/get-questions');
        let answer = await Axios.post('/faqs/get-answers', {});
        if (answer?.data?.data?.length > 0) {
            setCompletedQuestions(answer.data.data.length)
            let data = question.data.data.map((question) => {
                let index = answer.data.data.findIndex((faq) => faq.question_id == question.question_id)
                if (index > -1) {
                    return {
                        ...question,
                        answer: answer.data.data[index].answer,
                        last_updated: answer.data.data[index].last_updated
                    }
                }
                else {
                    return {
                        ...question,
                        answer: ''
                    }
                }

            })
            console.log(data)
            setLoading(false)
            setFaqs(data)
            
        }
        else {
            setCompletedQuestions(0)
            let data = question.data.data.map((question) => {
                return {
                    ...question,
                    answer: ''
                }
            })
            console.log(data)
            setLoading(false)
            setFaqs(data)
            
        }


    }
    const handleBeforeUnload = (e) => {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
  
        // Display confirmation message
        const confirmationMessage = 'Are you sure you want to leave this page?';
        e.returnValue = confirmationMessage; // For legacy browsers
  
        return confirmationMessage;
      };
    useEffect(() => {
        
            window.addEventListener('beforeunload', handleBeforeUnload);
          
          getQuestions();
          return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
          };
    }, []);

    // useEffect(() => {
    //     if(!isSaved){
    //         window.addEventListener('beforeunload', handleBeforeUnload);
    //     }
    //     else{
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     }
    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //       };
    // },[isSaved])

    
   
    return (
        <div className="bg-white">
            {loading ? <PartyLoader /> : null}
            <div className="mx-auto max-w-7xl px-6 py-8 ">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900 text-center ">Frequently Asked Questions</h2>
                    <p className="mt-4 text-sm text-gray-500 text-center py-2 leading-10 tracking-tight">Questions Answered :
                        <span className="font-semibold  text-gray-900 mr-0 md:mr-4 lg:mr-4"> {completedQuestions}/{faqsData.length}</span>
                    </p>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqsData?.length > 0 ?
                            <>
                                {faqsData.map((faq) => (
                                    <div key={faq.question} className="pt-6">
                                        
                                            <>
                                                <dt>
                                                    <div className="flex w-full items-start justify-between text-left text-gray-900">
                                                        <span className="text-base font-semibold leading-7 flex">{faq.question}
                                                        </span>
                                                    </div>
                                                </dt>
                                                <dd className="mt-2 pr-12">
                                                    <div >
                                                        <Textarea
                                                            label={""}
                                                            placeholder={"Answer"}
                                                            onChange={(value) => {
                                                                handleChange(value, faq)
                                                            }}
                                                            value={faq.answer}
                                                        />
                                                        {faq.last_updated && <span className="text-xs font-medium leading-7 text-gray-500"><span className='text-gray-600'>Last Updated:</span> {faq?.last_updated}</span>}
                                                    </div>
                                                </dd>
                                            </>
                                        
                                    </div>
                                
                                ))}
                            </>
                            : <div className="text-center">No faqs found</div>
                        }

                    </dl>
                </div>
                <div className="mt-8  w-full flex">
                    <PrimaryButton
                        type='button'
                        text="Save"
                        classNames={"mx-auto items-center"}
                        onClick={() => {
                            if (updatedFaqs?.length > 0) {
                                //let updatedFaqs = faqsData.filter((faq) => new RegExp(/\S.*\w/).test(faq.answer))
                                // console.log(updatedFaqs)
                                Axios.post('/faqs/update-answers', updatedFaqs).then((res) => {
                                    console.log(res)
                                    if (res.data.success) {
                                        getQuestions();
                                        toast("Updated successfully", { duration: 3000, clickable: true, className: 'my-toast-success', position: 'top-right' })
                                    }
                                    else {
                                        toast("Something went wrong", { duration: 3000, clickable: true, className: 'my-toast-failure', position: 'top-right' })
                                    }
                                })
                                setIsSaved(true)
                            }
                            else {
                                setIsSaved(false)
                                toast("Nothing to update", { duration: 3000, clickable: true, className: 'my-toast-failure', position: 'top-right' })
                            }
                        }
                        }
                    />

                </div>
            </div>
        </div>


    )
}
