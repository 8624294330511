import { Routes, Route, Navigate } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { firebaseApp } from "../lib/firebase";
import Layout from "../components/layout";
import Home from "../modules/home";
import Profile from "../modules/profile";
import Bookings from "../modules/bookings";
import Login from "../modules/auth/login";
import SignUp from "../modules/auth/signUp";
import Reviews from "../modules/reviews";
import Settings from "../modules/userSettings";
import FullScreenLoader from "../components/loader/fullScreenLoader";
import Faqs from "../modules/faqs";
import Axios from "../AxiosConfig";
// Login Routes



function AuthRouter() {
  
  return (
    <Routes>
      {/* Redirect / to dashboard */}
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/bookings" element={<Bookings />} />
      <Route path="/reviews" element={<Reviews />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/faqs" element={<Faqs />} />
      <Route path="*" element={<Navigate to="/" />} />
      
    </Routes>
  );
}

function LoginRouter() {
  return (
    <Routes>
      <Route path="/" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

function Router() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const auth = getAuth(firebaseApp);
  const navigate = useNavigate();
  useEffect( () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        onIdTokenChanged(auth, (token) => {
          if (token) {
            user.getIdToken().then(async function (idToken) {
              if (idToken) {
                window.localStorage.setItem("token", idToken);
                window.localStorage.setItem("user", JSON.stringify(user));
                let vendor = await Axios.post("/onboarding/create-vendor" , {'phone':user.phoneNumber , 'phoneWithCode':user.phoneNumber})
                if(vendor?.data.message =='User not found' || vendor?.data.message =='Error'){
                  window.localStorage.clear();
                  setIsLoggedIn(false);
                  setLoading(false);
                }
                if(vendor?.data?.message == 'User already exists' || vendor?.data?.message == 'User Created'){
                   if(!vendor?.data?.is_profile_completed){
                    setIsLoggedIn(true);
                    console.log("not completed")
                    navigate('/profile')
                   }
                    else{
                      setIsLoggedIn(true);
                      console.log(vendor.data?.data)
                      user.displayName = vendor.data?.data.name
                      user.email = vendor.data?.data.email
                      user.photoURL = vendor.data?.data.imageUrl
                      window.localStorage.setItem("user", JSON.stringify(user));
                      console.log("profile completed")
                      // navigate('/home')
                    }
                }
                
              } else {
                // Remove local storage
                console.log("id not found else router");
                window.localStorage.clear();
                setIsLoggedIn(false);
              }
              setLoading(false);
            });
          }
        });
      } else {
        // Remove local storage
        console.log("user not found else router");
        window.localStorage.clear();
        setIsLoggedIn(false);
        setLoading(false);
      }
    });
    console.log("useEffect in router");
  }, []);
  if (loading) {
    return <FullScreenLoader />;
  }
  if (isLoggedIn) {
    return (
      <Layout>
        <AuthRouter />
      </Layout>
    );
  } else {
    return <LoginRouter />;
  }
}
export default Router;
