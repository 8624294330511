import axios from "axios";
import { getAuth } from "firebase/auth";
const Axios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});
Axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.headers["Content-Type"] = "application/json";
  // headers: {"Access-Control-Allow-Origin": "*"}
  config.headers["Access-Control-Allow-Origin"] = "*";
  return config;
});

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        const auth = getAuth();
        console.log("error")
        // auth.signOut().then(() => {
        //   window.localStorage.clear();
        //   window.location.reload();
        // });
      }
    }
  }
);

export default Axios;
