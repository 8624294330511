import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Gleap from 'gleap';
import TagManager from 'react-gtm-module'
const root = ReactDOM.createRoot(document.getElementById("root"));

Gleap.initialize('x6wBMph8cFCoU84OU1fxOmpjogEVZcSp');

const gtm = {
  gtmId: 'GTM-NF2FDNH4'
}

TagManager.initialize(gtm)

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
