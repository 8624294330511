import React, { useState, useEffect } from 'react';
import PrimaryButton from '../../components/atoms/primaryButton';
import {useNavigate} from 'react-router-dom';
export default function Bookings() {

 const navigate = useNavigate();
  return (
    <div >
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Coming Soon {" "} 🚀</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            We are working on this feature. Please check back later.
          </p>
          <div className="mt-10 mx-auto gap-x-6">
           <PrimaryButton
              text="Go to Home"
              onClick={() => {
                navigate('/');
              }}
              />
           
          </div>
        </div>
      </main>
    </div>
    

  )
}
