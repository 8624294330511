import { CheckIcon } from '@heroicons/react/24/solid';
import React from 'react'
import { useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid'
export default function Steps({ tabArray }) {
  const [steps, setSteps] = useState(tabArray);
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className="lg:border-b lg:border-t lg:border-gray-200  bg-white">
      <nav className=" max-w-7xl mx-0 lg:mx-auto md:mx-auto " aria-label="Progress">
        <ol
          role="list"
          className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200 hidden lg:visible md:visible "
        >
          {steps?.map((step, stepIdx) => (
            <li key={step.id} className="relative overflow-hidden lg:flex-1">
              <div
                className={classNames(
                  stepIdx === 0 ? 'rounded-t-md border-b-0' : '',
                  stepIdx === steps.length - 1 ? 'rounded-b-md border-t-0' : '',
                  'overflow-hidden border border-gray-200 lg:border-0'
                )}
              >
                {step.status === 'complete' ? (
                  <div  className="group">
                    <span
                      className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? 'lg:pl-9' : '',
                        'flex items-start px-6 py-5 text-sm font-medium'
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-600">
                          <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                        <span className="text-sm font-medium">{step.name}</span>
                        <span className="text-sm font-medium text-gray-500">{step.description}</span>
                      </span>
                    </span>
                  </div>
                ) : step.status === 'current' ? (
                  <div  aria-current="step">
                    <span
                      className="absolute left-0 top-0 h-full w-1 bg-blue-600 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? 'lg:pl-9' : '',
                        'flex items-start px-6 py-5 text-sm font-medium'
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-blue-600">
                          <span className="text-blue-600">{step.id}</span>
                        </span>
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                        <span className="text-sm font-medium text-blue-600">{step.name}</span>
                        <span className="text-sm font-medium text-gray-500">{step.description}</span>
                      </span>
                    </span>
                  </div>
                ) : (
                  <div className="group">
                    <span
                      className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? 'lg:pl-9' : '',
                        'flex items-start px-6 py-5 text-sm font-medium'
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                          <span className="text-gray-500">{step.id}</span>
                        </span>
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                        <span className="text-sm font-medium text-gray-500">{step.name}</span>
                        <span className="text-sm font-medium text-gray-500">{step.description}</span>
                      </span>
                    </span>
                  </div>
                )}

                {stepIdx !== 0 ? (
                  <>
                    {/* Separator */}
                    <div className="absolute inset-0 left-0 top-0 hidden w-3 lg:block" aria-hidden="true">
                      <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vectorEffect="non-scaling-stroke" />
                      </svg>
                    </div>
                  </>
                ) : null}
              </div>
            </li>
          ))}
        </ol>
        <ol className="flex items-center w-full mx-auto mt-8 visible lg:hidden md:visible">
          {steps.map((step, stepIdx) => (
            
             <li 
              key={step.id}
               className={`${
                stepIdx === 0 ? ' flex flex-1 w-full items-center after:content-[""] after:w-full after:h-1 after:border-b  after:border-4 after:inline-block after:border-gray-100' 
                : stepIdx === 1 ? ' flex flex-1 w-full items-center after:content-[""] after:w-full after:h-1 after:border-b  after:border-4 after:inline-block after:border-gray-100' 
                :  'ml-0'
               }`}
             >
              <div className={`flex items-center justify-center w-12 h-12 ${step.status ==='current'?'bg-blue-100' :step.status === 'complete' ? 'bg-blue-400': 'bg-gray-200' } rounded-full shrink-0`}>
                {step.status === 'complete' ? (
                  <CheckCircleIcon className="w-6 h-6 text-blue-600 " aria-hidden="true" />
                ) : step.status === 'current' ? (
                  <span className="text-blue-600">{step.icon}</span>
                ) : (
                  <span className="text-gray-500">{step.icon}</span>
                )}
              </div>
             

             </li>
          ))}
        </ol>
        {/* <ol className="flex items-center visible lg:hidden md:visible ">
          {steps.map((step, stepIdx) => (
            <li key={step.name}
              className={classNames(stepIdx !== steps.length - 1 ? 'pr-12 mt-2 ' : '', 'relative mt-2 ')}>
              {step.status === 'complete' ? (
                <>
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="h-0.5 w-full bg-blue-600" />
                  </div>
                  <a href="#"
                    className="relative flex h-12 w-12 items-center justify-center rounded-full bg-blue-600 hover:bg-blue-900">
                    <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    <span className="sr-only">{step.name}</span>
                  </a>
                </>
              ) : step.status === 'current' ? (
                <>
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <a href="#"
                    className="relative flex h-12 w-12 items-center justify-center rounded-full border-2 border-blue-600 bg-white text-blue-500"
                    aria-current="step">
                    {step.icon}
                    <span className="sr-only">{step.name}</span>
                  </a>
                </>
              ) : (
                <>
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <a href="#"
                    className="group relative flex h-12 w-12 items-center justify-center rounded-full border-2 border-gray-300 text-gray-500 bg-white hover:border-gray-400">
                    {step.icon}
                    <span className="sr-only">{step.name}</span>
                  </a>
                </>
              )}
            </li>
          ))}
        </ol> */}
      </nav>
    </div>
  )
}
