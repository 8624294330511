const SecondaryButton = ({ text, disbabled, onClick,classNames,type="button" }) =>{
    return (
      <button
        type={type}
        onClick={onClick}
        disabled={disbabled}
        className= {`rounded-md bg-white px-8 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
         focus-visible:outline-neutral-600  ${classNames}`}
      >
        {text}
      </button>
    );
  }
  export default SecondaryButton;
  