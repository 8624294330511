const CheckBox = ({ label, checked, onChange }) => {
  return (
    <>
      <label className="flex items-center">
        <input
          type="checkbox"
          className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 focus:ring-offset-2"
          checked={checked}
          onChange={(e) => {onChange(e.target.checked)
          console.log(e.target.checked)
        }}
        />
        <span className="ml-2 text-sm text-gray-500">{label}</span>
      </label>
    </>
  );
}
export default CheckBox;