import React , {useEffect,useRef} from 'react';
import { Player } from '@lordicon/react';
const ICON = require('./champagne.json');
export default function PartyLoader({}) {
    const playerRef = useRef(null);
    useEffect(() => {
        playerRef.current?.playFromBeginning();
        }, []);
    return (
      <div className="fixed top-0 left-0 w-screen h-screen flex flex-col justify-center items-center bg-white z-50">
        <Player ref={playerRef} size={96} icon={ICON}  />
      </div>
    );
  }
  