

const Input = ({ label, onChange,
  onBlur  = () => {}
  , optionalText = '',msgText='',placeholder, type = "text", value = "", isDisabled = false, name = 'default', addOn = false, addOnText = '', isOptional = false }) => {
    
  return (
    <div className="">
      <div className="flex justify-between items-end">
        <label
          htmlFor="default-input"
          className="block mb-0.5 text-sm font-semibold text-gray-900"
        >
          {label}
        </label>
        {isOptional &&
          <span className="text-xs  font-semibold leading-6 text-gray-700" >
            {optionalText}
          </span>
        }
      </div>
      {msgText.length > 0 &&
      <div className="flex">
        <label
          htmlFor="default-input"
          className="block  text-xs font-medium text-gray-500"
        >
         {msgText}
        </label>
      </div>
     }
      <div className="mt-2 flex rounded-md shadow-sm">
        {addOn &&
          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
            {addOnText}
          </span>
        }
        <input
          type={type}
          id="default-input"
          placeholder={placeholder}
          disabled={isDisabled}
          value={value}
          name={name}
          onChange={(e) => onChange(
            // addOnText.length > 0 && addOnText !== '$'? addOnText + e.target.value : e.target.value
            e.target.value
          )}
          onBlur={(e) => onBlur(
            e.target.value
          )}
          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
        disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed
        ${addOn ? ' rounded-r-md' : 'rounded-lg'}`}
        />
      </div>
    </div>
  );
}
export default Input;

