import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition ,Menu} from "@headlessui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { firebaseApp, firebaseDB } from "../../lib/firebase";



import {
  Bars3Icon,
  XMarkIcon,
  ArrowLeftOnRectangleIcon,
  HomeIcon,
  UserIcon,
  CalendarIcon,
  ChatBubbleBottomCenterIcon,
  BellIcon,
  ChevronDownIcon,
  QuestionMarkCircleIcon
} from "@heroicons/react/24/outline";

const navigationItems = [
  { name: "Home", href: "/home", icon: HomeIcon, current: false },
  {name : "Bookings", href: "/bookings", icon: CalendarIcon, current: false},
  {name : "Reviews", href: "/reviews", icon: ChatBubbleBottomCenterIcon, current: false},
  {name : "Profile", href: "/profile", icon: UserIcon, current: false},
  {name : "Faqs", href: "/faqs", icon: QuestionMarkCircleIcon, current: false}


];
const userNavigation = [
  { name: 'User Settings', href: '/settings' },
  { name: 'Sign out', href: '#' },
]
// const subMenuItems = [
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth(firebaseApp);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navigation, setNavigation] = useState(navigationItems);
  

  
  let user = window.localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    auth.signOut();
    window.location.reload();
  }
  // console.log("in layout index",user);


  useEffect(() => {
    let currentPath = window.location.pathname;
    // If path contains 2 /, then take the first part
    if (currentPath.split("/").length > 2) {
      currentPath = `/${currentPath.split("/")[1]}`;
    }
    const newNavigation = navigation.map((item) => {
      if (item.href === currentPath) {
        return { ...item, current: true };
      }
      return { ...item, current: false };
    });
    setNavigation(newNavigation);
  }, [location]);
  return (
    <>
      <div>
        {/* Navigation For Mobile */}

        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-neutral-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto sidebar-bg px-6 pb-2 ring-1 ring-white/10">
                    <nav className="flex flex-1 flex-col mt-4">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <div
                                  onClick={() => {
                                    navigate(item.href);
                                    setSidebarOpen(false);
                                  }}
                                  className={classNames(
                                    item.current
                                      ? "text-white bg-white/20"
                                      : "text-gray-400 hover:text-white hover:bg-white/10",
                                    "cursor-pointer group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </li>
                       
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto sidebar-bg px-6">
            <div className="text-center"></div>
            <nav className="flex flex-1 flex-col mt-8">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <div
                          onClick={() => {
                            navigate(item.href);
                          }}
                          className={classNames(
                            item.current
                              ? "text-white bg-white/20"
                              : "text-gray-400 hover:text-white hover:bg-white/10",
                            "cursor-pointer group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {item.name}
                        </div>
                      </li>
                    ))}
                  </ul>
                </li>
                
                {/* <li className="-mx-6 mt-auto">
                  <div className="flex items-center justify-between gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white">
                    <div className="flex">
                      <img
                        className="h-10 w-10 rounded-full bg-gray-800"
                        src={
                          user.photoURL
                            ? user.photoURL
                            : "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        }
                        referrerPolicy="no-referrer"
                        alt=""
                      />
                      <div className="ml-4">
                        <span className="sr-only">Your profile</span>
                        <p aria-hidden="true" className="mb-0">
                          {user.displayName}
                        </p>

                      </div>
                    </div>
                  </div>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
        {/* Top Bar For Mobile */}
        {/* <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-[#061e34] px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 flex items-center justify-end">
            <ArrowLeftOnRectangleIcon
              onClick={() => {
                auth.signOut();
                window.location.reload();
              }}
              className="ml-4 h-6 w-6 cursor-pointer text-red-500"
            />
          </div>
        </div> */}


        {/* Navbar */}
        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 justify-end">
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5 ">
                  <div className="flex">
                      <img
                        className="h-10 w-10 rounded-full bg-gray-800 object-cover"
                        src={
                          user.photoURL
                            ? user.photoURL
                            : "https://icon-library.com/images/user-image-icon/user-image-icon-17.jpg"
                        }
                        referrerPolicy="no-referrer"
                        alt=""
                      />
                      
                      <div className="ml-4 mt-2 text-sm lg:text-base">
                        <span className="sr-only">Your profile</span>
                        <p aria-hidden="true" className="mb-0">
                          {user.displayName}
                        </p>

                      </div>
                    </div>
                    <span className="sr-only">Open user menu</span>
                   
                    <span className="flex items-center">
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                    
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (

                            <a
                              href={item.href}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                              )}
                              onClick={()=>{
                                if(item.name === 'Sign out'){
                                  auth.signOut();
                                  window.location.reload();
                                }
                              }}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main >
            <div className="px-4 sm:px-6 lg:px-8">{/* Your content */}</div>
          </main>
        </div>

        <main className="py-4 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">{children}</div>
        </main>
      </div>
    </>
  );
}
